<template>
  <div class="container-fluid">
    <b-modal
      ref="edit-order-revenue-modal"
      size="lg"
      :title="$t('orderMsgs.manageRevenue')"
      hide-footer
      centered
      static
      lazy
      @hide="hideModal"
    >
      <div class="row">
        <div class="col-lg-12">
          <div>
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="form-group col-md-12">
                  <label for="materialRev">{{ $t('orderMsgs.materialRev') }}</label>
                  <currency-input
                    class="form-control"
                    id="materialRev"
                    :placeholder="$t('orderMsgs.materialRev')"
                    v-model="materialRev"
                    :precision="2"
                    :allow-negative="false"
                    required
                  />
                </div>

                <div class="form-group col-md-12">
                  <label for="laborRev">{{ $t('orderMsgs.laborRev') }}</label>
                  <currency-input
                    class="form-control"
                    id="laborRev"
                    :placeholder="$t('orderMsgs.laborRev')"
                    v-model="laborRev"
                    :precision="2"
                    :allow-negative="false"
                    required
                  />
                </div>
              </div>

              <div class="text-right">
                <b-button type="submit" variant="primary" :disabled="isLoading" style="min-width: 100px">
                  <i v-if="isLoading" class="fas fa-circle-notch fa-spin" />
                  <span v-else>{{ $t('update') }}</span>
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'EditOrderRevenueModal',

  props: {
    showModal: Boolean,
    order: Object,
    orderId: Number,
  },

  data() {
    return {
      materialRev: null,
      laborRev: null,

      isLoading: false,
    };
  },

  methods: {
    ...mapActions(['updateOrder', 'getOrder']),

    resetData() {
      this.materialRev = this.laborRev = null;
      this.isLoading = false;
    },

    hideModal() {
      this.$emit('close');
      // this.resetData();
    },

    hideModalManual() {
      this.$refs['edit-order-revenue-modal'].hide();
      this.resetData();
      this.hideModal();
    },

    hideWithUpdateModal() {
      this.$refs['edit-order-revenue-modal'].hide();
      this.$emit('closeUpdate', this.materialRev, this.laborRev);
      this.resetData();
    },

    async fetchOrderData() {
      this.isLoading = true;

      try {
        this.order = (await this.getOrder(this.orderId)).data;

        this.materialRev = this.order.material_revenue;
        this.laborRev = this.order.labor_revenue;
      } catch (err) {
        this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
        this.hideModalManual();
      }

      this.isLoading = false;
    },

    async onSubmit() {
      this.isLoading = true;
      try {
        await this.updateOrder({
          id: this.orderId,
          data: { material_revenue: this.materialRev, labor_revenue: this.laborRev },
        });

        this.makeToast('success', this.$t('orderMsgs.revenueUpdated'));
        this.isLoading = false;
        this.hideWithUpdateModal();
      } catch (error) {
        this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
      }

      this.isLoading = false;
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },
  },

  async mounted() {},

  computed: {
    ...mapGetters(['getCurrLanguage']),
  },

  watch: {
    showModal(value) {
      if (value && this.orderId) {
        this.materialRev = this.order.material_revenue;
        this.laborRev = this.order.labor_revenue;
        this.$refs['edit-order-revenue-modal'].show();
      }
    },
  },
};
</script>
