<template>
  <div class="container-fluid">
    <!-- Search Bar -->
    <div class="row">
      <div class="m-auto col-md-6">
        <div class="card card-block">
          <div class="card-body">
            <div class="mm-search-bar">
              <form action="#" class="searchbox">
                <a class="search-link" href="#"><i class="fas fa-search"></i></a>
                <v-select
                  class="search-input v-select-search"
                  :placeholder="`${$t('orderMsgs.searchOrders')}...`"
                  :options="allOrders"
                  label="order_number"
                  v-model="order"
                  :loading="isLoading || isRouteOrderLoading"
                  :filterable="false"
                  :clearSearchOnSelect="false"
                  @search="onSearch"
                >
                  <template #search="{ attributes, events }">
                    <input class="vs__search" v-bind="attributes" v-on="events" />
                  </template>

                  <template slot="option" slot-scope="option">
                    {{ option.order_number }} - {{ option.customer.name }}
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    {{ option.order_number }}
                  </template>

                  <template slot="no-options">
                    {{ orderSearchNoResult ? $t('orderMsgs.noOrderFoundSearch') : $t('generalMsgs.typeToSearch') }}
                  </template>

                  <!-- Uncomment if infinite scrolling is implemented on search. -->
                  <!-- <template #list-footer>
                    <li ref="load" class="test-loader" v-show="hasMoreOrders">
                      {{ $t("generalMsgs.loadingMoreRes") }}
                    </li>
                  </template> -->

                  <template #list-footer>
                    <li ref="load" class="vs-search-more" v-if="firstLoad">
                      {{ $t('orderMsgs.typeToSearchMore') }}
                    </li>
                  </template>
                </v-select>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Overview & Shortcuts -->
    <div class="row">
      <!-- Order Overview Card -->
      <div class="col-lg-9" v-if="order">
        <div class="card card-block card-stretch card-height">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title mb-0">
                {{ $t('overview') }}
              </h4>
            </div>

            <!-- <router-link :to="{ name: 'add-order' }" class="btn btn-primary">
              {{ $t("orderMsgs.addOrder") }}
            </router-link> -->
          </div>
          <div class="card-body">
            <b-row class="text-center">
              <!-- <b-col cols="6" md="3" sm="3">
                <strong>{{ $t("orderMsgs.orderNum") }}:</strong>
              </b-col> -->
              <b-col cols="12" md="12" sm="12">
                <h4>
                  <strong># {{ order.order_number }}</strong>
                </h4>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('orderMsgs.receivedDate') }}:</strong>
              </b-col>
              <b-col cols="6" md="10" sm="9">
                <strong>{{ formatDate(order.received_date) }} </strong>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('createdAt') }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                <strong>{{ formatDateTime(order.created_at) }} </strong>
              </b-col>

              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('createdBy') }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                <strong
                  >{{ order.created_by ? `${order.created_by.first_name} ${order.created_by.last_name}` : '-' }}
                </strong>
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('customerMsgs.customer') }}:</strong>
              </b-col>
              <b-col cols="6" md="10" sm="9">
                <i>{{ order.customer.customer_number }}</i> -
                {{ order.customer.name }}
              </b-col>
              <!-- <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("customerMsgs.custNum") }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                {{ order.customer.customer_number }}
              </b-col> -->

              <!-- <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("customerMsgs.customer") }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                {{ order.customer.name }}
              </b-col>
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("customerMsgs.custNum") }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                {{ order.customer.customer_number }}
              </b-col> -->
            </b-row>

            <b-row class="mt-2">
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('orderMsgs.constructionProj') }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                {{ order.construction_project }}
              </b-col>
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('costCenterMsgs.costCenter') }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                <i>{{ order.cost_center.center_number }}</i>
                {{ order.cost_center.name ? ` - ${order.cost_center.name}` : '' }}
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('orderMsgs.sheetNums') }}:</strong>
              </b-col>
              <b-col cols="6" md="10" sm="9">
                {{ order.sheet_numbers }}
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('orderMsgs.deliveryNote') }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                <i v-if="isDelNoteLoading">{{ $t('generalMsgs.loading') }}...</i>
                <span v-else>{{ deliveryNote ? deliveryNote.delivery_note_number : '--' }}</span>
              </b-col>
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('date') }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                <i v-if="isDelNoteLoading">{{ $t('generalMsgs.loading') }}...</i>
                <span v-else>{{ deliveryNote ? formatDate(deliveryNote.date) : '--' }}</span>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('orderMsgs.invoice') }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                <i v-if="isInvoiceLoading">{{ $t('generalMsgs.loading') }}...</i>
                <span v-else>{{ invoice ? invoice.invoice_number : '--' }}</span>
              </b-col>
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('date') }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                <i v-if="isInvoiceLoading">{{ $t('generalMsgs.loading') }}...</i>
                <span v-else>{{ invoice ? formatDate(invoice.invoice_date) : '--' }}</span>
              </b-col>
            </b-row>

            <hr />

            <h4>{{ $t('orderMsgs.costs') }}</h4>

            <b-row class="mt-2">
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('materialMsgs.material') }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                <i v-if="isCostLoading">{{ $t('generalMsgs.loading') }}...</i>
                <span v-else>
                  {{ $n(order.cost.material_cost || 0, 'currency', 'de-DE') }}
                </span>
              </b-col>
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('orderMsgs.wage') }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                <i v-if="isCostLoading">{{ $t('generalMsgs.loading') }}...</i>
                <span v-else>
                  {{ $n(order.cost.labor_cost || 0, 'currency', 'de-DE') }}
                </span>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('orderMsgs.total') }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                <i v-if="isCostLoading">{{ $t('generalMsgs.loading') }}...</i>
                <span v-else>
                  {{ $n(order.cost.raw_cost || 0, 'currency', 'de-DE') }}
                </span>
              </b-col>
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('orderMsgs.total') }} ({{ $t('orderMsgs.inclWaste') }}):</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                <i v-if="isCostLoading">{{ $t('generalMsgs.loading') }}...</i>
                <span v-else>
                  {{ $n(order.cost.total_cost || 0, 'currency', 'de-DE') }}
                </span>
              </b-col>
            </b-row>

            <hr />

            <h4>{{ $t('orderMsgs.revenue') }}</h4>

            <b-row class="mt-2">
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('materialMsgs.material') }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                {{ $n(order.material_revenue, 'currency', 'de-DE') }}
              </b-col>
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('orderMsgs.wage') }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                {{ $n(order.labor_revenue, 'currency', 'de-DE') }}
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('orderMsgs.total') }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                {{ $n(order.rawRevTotal, 'currency', 'de-DE') }}
              </b-col>

              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('orderMsgs.total') }} ({{ $t('orderMsgs.inclFactor') }}):</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                {{ $n(order.totalRevenue, 'currency', 'de-DE') }}
              </b-col>
            </b-row>
          </div>
        </div>
      </div>

      <!-- Shortcuts Card -->
      <div :class="order ? 'col-lg-3' : 'm-auto col-lg-6'">
        <div class="card card-block">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title mb-0">
                {{ $t('generalMsgs.shortcuts') }}
              </h4>
            </div>
          </div>
          <div class="card-body">
            <router-link
              :to="{ name: 'add-order', query: { redirect: this.$route.name } }"
              class="btn btn-secondary col-md-12"
            >
              <i class="fas fa-plus-circle" />
              {{ $t('orderMsgs.addNewOrder') }}
            </router-link>

            <a href="#" @click.prevent="openUploadBillModal()" class="btn btn-secondary col-md-12 mt-3">
              <i class="fas fa-file-invoice" />
              {{ $t('billPaperMsgs.uploadNewBill') }}
            </a>

            <div v-if="order">
              <hr />

              <router-link
                :to="{
                  name: 'edit-order',
                  params: { id: order.id },
                  query: { redirect: this.$route.name },
                }"
                class="btn btn-secondary col-md-12"
              >
                <i class="far fa-edit" />
                {{ $t('orderMsgs.editOrder') }}
              </router-link>

              <a href="#" @click.prevent="openUsedMaterialsModal()" class="btn btn-secondary col-md-12 mt-3">
                <i class="fas fa-cubes" />
                {{ $t('materialMsgs.manageMaterials') }}
              </a>

              <a href="#" @click.prevent="openPostInvoiceModal()" class="btn btn-secondary col-md-12 mt-3">
                <i class="fas fa-file-invoice-dollar" />
                {{ $t('invoiceMsgs.manageInvoice') }}
              </a>

              <a href="#" @click.prevent="openPostDeliveryNoteModal()" class="btn btn-secondary col-md-12 mt-3">
                <i class="fas fa-receipt" />
                {{ $t('deliveryNoteMsgs.manageDeliveryNote') }}
              </a>

              <a href="#" @click.prevent="openEditLaborHrsModal()" class="btn btn-secondary col-md-12 mt-3">
                <i class="fas fa-hammer" />
                {{ $t('orderMsgs.manageLaborHrs') }}
              </a>
              <a href="#" @click.prevent="openEditRevenueModal()" class="btn btn-secondary col-md-12 mt-3">
                <i class="fas fa-euro-sign" />
                {{ $t('orderMsgs.manageRevenue') }}
              </a>

              <hr v-if="isSheetPrintDataLoaded || (isDelNotePrintDataLoaded && deliveryNote)" />

              <a
                href="#"
                @click.prevent="printOrderSheet"
                class="btn btn-secondary col-md-12"
                v-if="isSheetPrintDataLoaded"
              >
                <i class="fas fa-print" />
                {{ $t('orderMsgs.printOrderSheet') }}
              </a>

              <a
                href="#"
                @click.prevent="printDelNote"
                class="btn btn-secondary col-md-12 mt-3"
                v-if="isDelNotePrintDataLoaded && deliveryNote"
              >
                <i class="fas fa-print" />
                {{ $t('deliveryNoteMsgs.printDeliveryNote') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <order-sheet-print
      v-if="order"
      ref="orderSheetPrint"
      :orderId="order.id"
      :orderData="order"
      @dataLoaded="isSheetPrintDataLoaded = true"
    />

    <multi-page-del-note-print
      v-if="order && deliveryNote"
      ref="delNotePrint"
      :orderId="order.id"
      :orderData="order"
      :delNoteData="deliveryNote"
      @dataLoaded="isDelNotePrintDataLoaded = true"
      @noteFound="hasDelNote = true"
    />

    <upload-bill-modal
      :showModal.sync="modals.showUploadBillModal"
      @close="hideUploadBillModal"
      @closeUpdate="hideUpdateUploadBillModal"
    />

    <post-delivery-note-modal
      v-if="order"
      :showModal.sync="modals.showPostDeliveryNoteModal"
      :orderId="order.id"
      :order="order"
      @close="hidePostDeliveryNoteModal"
    />

    <post-invoice-modal
      v-if="order"
      :showModal.sync="modals.showPostInvoiceModal"
      :orderId="order.id"
      :order="order"
      @close="hidePostInvoiceModal"
    />

    <used-material-list-modal
      v-if="order"
      :showModal.sync="modals.showUsedMaterialsModal"
      :orderId="order.id"
      :order="order"
      @close="hideUsedMaterialsModal"
    />

    <edit-order-labor-hrs-modal
      v-if="order"
      :showModal="modals.showEditLaborHrsModal"
      :orderId="order.id"
      @close="hideEditLaborHrsModal"
      @closeUpdate="hideUpdateEditLaborHrsModal"
    />
    <edit-order-revenue-modal
      v-if="order"
      :showModal="modals.showEditRevenueModal"
      :order="order"
      :orderId="order.id"
      @close="hideEditRevenueModal"
      @closeUpdate="hideUpdateEditRevenueModal"
    />
  </div>
</template>


<script>
import { mapActions } from 'vuex';
// import { DEFAULT_PAGE_SIZE } from "../../common/constants";
import { formatDate, formatDateTime } from '../../common/utils';
import vSelect from 'vue-select';
import { debounce, get, head } from 'lodash';
import PostDeliveryNoteModal from './DeliveryNotes/PostDeliveryNoteModal';
import PostInvoiceModal from './Invoices/PostInvoiceModal';
import UsedMaterialListModal from './UsedMaterials/UsedMaterialListModal';
import UploadBillModal from '../Bills/UploadBillModal';
import OrderSheetPrint from '../Orders/OrderDetail/OrderSheetPrint';
import MultiPageDelNotePrint from './DeliveryNotes/MultiPageDelNotePrint.vue';
import EditOrderLaborHrsModal from '../Orders/EditOrderLaborHrsModal';
import EditOrderRevenueModal from '../Orders/EditOrderRevenueModal.vue';

export default {
  name: 'OrderList',

  components: {
    vSelect,
    PostDeliveryNoteModal,
    PostInvoiceModal,
    UsedMaterialListModal,
    UploadBillModal,
    OrderSheetPrint,
    MultiPageDelNotePrint,
    EditOrderLaborHrsModal,
    EditOrderRevenueModal,
  },

  data() {
    return {
      allOrders: [],
      currentPage: 1,
      pageSize: 30,
      totalOrders: 0,

      order: null,
      deliveryNote: null,
      invoice: null,

      isLoading: false,
      isRouteOrderLoading: false,
      isDelNoteLoading: false,
      isInvoiceLoading: false,
      isCostLoading: false,
      isSheetPrintDataLoaded: false,
      isDelNotePrintDataLoaded: false,
      hasDelNote: false,

      firstLoad: false,
      orderSearchNoResult: false,
      // searchObserver: new IntersectionObserver(this.infiniteSearchScroll),

      modals: {
        selectedOrder: null,
        selectedOrderId: null,
        showPostDeliveryNoteModal: false,
        showPostInvoiceModal: false,
        showUsedMaterialsModal: false,
        showUploadBillModal: false,
        showEditLaborHrsModal: false,
        showEditRevenueModal: false,
      },
    };
  },

  methods: {
    ...mapActions(['getAllOrders', 'getOrder', 'deleteOrder', 'getDeliveryNotes', 'getInvoices', 'getOrdersCosts']),
    formatDate,
    formatDateTime,

    async fetchAndSetOrders(pageNum = 1, params = {}, firstLoad = false, refillOrders = true) {
      this.isLoading = true;

      const response = await this.getAllOrders({
        limit: firstLoad ? 10 : this.pageSize,
        offset: (pageNum - 1) * this.pageSize,
        ...params,
      });
      // this.allOrders = response.data;

      if (refillOrders) {
        this.allOrders = response.data.results;
      } else {
        this.allOrders = this.allOrders.concat(response.data.results);
      }
      this.currentPage = pageNum;
      this.totalOrders = response.data.count;

      if (!this.totalOrders) {
        this.orderSearchNoResult = true;
      }

      if (firstLoad) {
        // Add this if default selection of latest order is required.
        // this.order = this.allOrders[0];
        this.firstLoad = true;
      } else {
        this.firstLoad = false;
      }

      this.isLoading = false;
    },

    openPostDeliveryNoteModal() {
      this.modals.showPostDeliveryNoteModal = true;
    },

    hidePostDeliveryNoteModal() {
      this.modals.showPostDeliveryNoteModal = false;
      this.fetchDeliveryNote();
    },

    openPostInvoiceModal() {
      this.modals.showPostInvoiceModal = true;
    },

    hidePostInvoiceModal() {
      this.modals.showPostInvoiceModal = false;
      this.fetchInvoice();
    },

    openUsedMaterialsModal() {
      this.modals.showUsedMaterialsModal = true;
    },

    hideUsedMaterialsModal() {
      this.modals.showUsedMaterialsModal = false;
      this.fetchOrderCost();
    },

    openUploadBillModal() {
      this.modals.showUploadBillModal = true;
    },

    hideUploadBillModal() {
      this.modals.showUploadBillModal = false;
    },

    hideUpdateUploadBillModal() {
      this.hideUploadBillModal();
      this.$router.push({ name: 'list-bills' });
    },

    openEditLaborHrsModal() {
      this.modals.showEditLaborHrsModal = true;
    },
    openEditRevenueModal() {
      this.modals.showEditRevenueModal = true;
    },

    hideEditLaborHrsModal() {
      this.modals.showEditLaborHrsModal = false;
    },
    hideEditRevenueModal() {
      this.modals.showEditRevenueModal = false;
    },
    hideUpdateEditLaborHrsModal() {
      this.modals.showEditLaborHrsModal = false;
      this.fetchOrderCost();
    },
    hideUpdateEditRevenueModal(materialRev, laborRev) {
      this.modals.showEditRevenueModal = false;
      this.order.material_revenue = materialRev;
      this.order.labor_revenue = laborRev;

      this.order['rawRevTotal'] = materialRev + laborRev;
      this.order['totalRevenue'] = (materialRev + laborRev) * this.order.revenue_factor;
    },

    onSearch(search) {
      this.debouncedSearchOrders(search, this);
    },

    async confirmDeleteOrder(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(this.$t('orderMsgs.deleteConfirm'), {
          title: this.$t('generalMsgs.suretyMsg'),
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('generalMsgs.yes'),
          cancelTitle: this.$t('generalMsgs.no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        });
        if (isConfirmed) {
          await this.deleteOrder(id);
          const refreshPage = this.allOrders.length > 1 ? this.currentPage : this.currentPage - 1;
          this.fetchAndSetOrders(refreshPage || 1);
          this.makeToast('success', this.$t('generalMsgs.deletedSuccess'));
        }
      } catch (error) {
        this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
      }
    },

    // TODO: Uncomment if infinite scrolling is implemented on search.
    // async onOpen() {
    //   if (this.hasMoreOrders) {
    //     await this.$nextTick();
    //     this.searchObserver.observe(this.$refs.load);
    //   }
    // },

    // onClose() {
    //   this.searchObserver.disconnect();
    // },

    // async infiniteSearchScroll([{ isIntersecting, target }]) {
    //   if (isIntersecting) {
    //     const ul = target.offsetParent;
    //     const scrollTop = target.offsetParent.scrollTop;

    //     this.fetchAndSetOrders(this.currentPage + 1);

    //     await this.$nextTick();
    //     ul.scrollTop = scrollTop;
    //   }
    // },

    debouncedSearchOrders: debounce(async (search, vm) => {
      vm.orderSearchNoResult = false;
      if (search) {
        vm.fetchAndSetOrders(1, { ...(search && { search }) });
      } else {
        vm.allOrders = [];
      }
    }, 500),

    async fetchDeliveryNote() {
      this.isDelNoteLoading = true;
      this.deliveryNote = null;

      if (this.order) {
        try {
          const note = (await this.getDeliveryNotes({ order: this.order.id })).data;

          if (get(note, 'length')) {
            this.deliveryNote = head(note);
          }
        } catch (err) {
          this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
        }
      }

      this.isDelNoteLoading = false;
    },

    async fetchInvoice() {
      this.isInvoiceLoading = true;
      this.invoice = null;

      if (this.order) {
        try {
          const invoices = (await this.getInvoices({ order: this.order.id })).data;

          if (get(invoices, 'length')) {
            this.invoice = head(invoices);
          }
        } catch (err) {
          this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
        }
      }

      this.isInvoiceLoading = false;
    },

    async fetchOrderCost() {
      this.isCostLoading = true;

      const costs = (await this.getOrdersCosts({ orderIds: [this.order.id] })).data;
      this.order['cost'] = head(costs) || {};

      this.isCostLoading = false;
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },

    async fetchOrderDetails() {
      if (this.order) {
        this.fetchDeliveryNote();
        this.fetchInvoice();
        this.fetchOrderCost();
        this.order['rawRevTotal'] = this.order.material_revenue + this.order.labor_revenue;
        this.order['totalRevenue'] =
          (this.order.material_revenue + this.order.labor_revenue) * this.order.revenue_factor;
      }
    },

    printOrderSheet() {
      this.$refs.orderSheetPrint.print();
    },

    printDelNote() {
      this.$refs.delNotePrint.print();
    },

    async fetchRouteOrder() {
      this.isRouteOrderLoading = true;
      const orderId = this.$route.params.order;
      if (orderId) {
        try {
          this.order = (await this.getOrder(orderId)).data;
        } catch (_err) {
          // Handle error if needed
        }
      }
      this.isRouteOrderLoading = false;
    },
  },

  mounted() {
    this.fetchRouteOrder();
    this.fetchAndSetOrders(1, {}, true);
  },

  computed: {
    // TODO: Uncomment if infinite scrolling is implemented on search.
    // hasMoreOrders() {
    //   return this.allOrders.length < this.totalOrders;
    // },
  },

  watch: {
    order: function () {
      this.fetchOrderDetails();
    },
  },
};
</script>

